import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../App.css";
import { Row, Col, Image, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "./HomeScreen.css";
import { LinkContainer } from "react-router-bootstrap";
import image from "../images/homeImg.jpg";
import image2 from "../images/crownPlaza.jpg";

import eeLogo from "../images/eeLogo.jpg";
import speakerVishal from "../images/strotVishal.jpg";

const EEHomeScreen = () => {
  const user = useSelector((state) => state.authentication.user);

  const [timeRemaining, setTimeRemaining] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const targetDate = new Date("2023-07-21T12:39:00");
    let interval;

    const calculateTimeRemaining = () => {
      const currentTime = new Date();
      const timeDifference = targetDate - currentTime;

      if (timeDifference < 0) {
        clearInterval(interval);
        setTimeRemaining("Expired");
      } else {
        const totalSeconds = Math.floor(timeDifference / 1000);
        const days = Math.floor(totalSeconds / (3600 * 24));
        const hours = Math.floor((totalSeconds / 3600) % 24);
        const minutes = Math.floor((totalSeconds / 60) % 60);
        const seconds = Math.floor(totalSeconds % 60);

        setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
      }
    };

    calculateTimeRemaining();
    interval = setInterval(calculateTimeRemaining, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  // const fetchData = async () => {
  //   const response = await axios.get(`/user/user/${user?.user_id}`);
  //   setInfos(response.data);
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  return (
    <div>
      <div className="homeContainer">
        <div class="bg-gradient-blur-wrapper-left">
          <div class="bg-gradient-blur-circle-3-left"></div>
          <div class="bg-gradient-blur-circle-2-left"></div>
          <div class="bg-gradient-blur-circle-1-left"></div>
        </div>
        <div class="bg-gradient-blur-wrapper-right">
          <div class="bg-gradient-blur-circle-3-right"></div>
          <div class="bg-gradient-blur-circle-2-right"></div>
          <div class="bg-gradient-blur-circle-1-right"></div>
        </div>
        <img src={eeLogo} className="eeLogo" />
        <h1 className="homeHead">EXCLUSIVE EMPRESAAS</h1>
        <h2 className="homeHeadTag">Opportunities Unlimited</h2>
        <p className="homeSubHead">The Crowne Plaza, Pune India</p>
        <p className="homeSubHead">26th August 2023 - 27th August 2023</p>

        <div className="registrationContainer">
          {(() => {
            if (
              typeof user !== "undefined" &&
              typeof user.username !== "undefined"
            ) {
              return (
                <LinkContainer to="/ee/register">
                  <Row>
                    <h3
                      className="registraionHead"
                      style={{ textAlign: "center", fontSize: "60px" }}
                    >
                      <Button
                        className="btn--outline"
                        style={{ fontSize: "26px" }}
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Register Now
                      </Button>
                    </h3>
                  </Row>
                </LinkContainer>
              );
            } else
              return (
                <LinkContainer to="/sign-up">
                  <Row>
                    <h3
                      className="registraionHead"
                      style={{ textAlign: "center", fontSize: "60px" }}
                    >
                      <Button
                        className="btn--outline"
                        style={{ fontSize: "26px" }}
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Register Now
                      </Button>
                    </h3>
                  </Row>
                </LinkContainer>
              );
          })()}
        </div>
      </div>
      <div className="homeContainer2">
        <div className="infoContainer">
          <div class="bg-gradient-blur-wrapper-center-left">
            <div class="bg-gradient-blur-circle-3-center-left"></div>
            <div class="bg-gradient-blur-circle-2-center-left"></div>
            <div class="bg-gradient-blur-circle-1-center-left"></div>
          </div>
          <div class="bg-gradient-blur-wrapper-center-right">
            <div class="bg-gradient-blur-circle-3-left"></div>
            <div class="bg-gradient-blur-circle-2-left"></div>
            <div class="bg-gradient-blur-circle-1-left"></div>
          </div>
          <Row>
            <Col md={7} className="infoCol">
              <p className="homeHeadText">
                Join us at Exclusive Empresas Business Summit - a golden
                opportunity awaits you! Discover new horizons for your business
                as you explore potential financiers for your startup or find
                reliable partners to expand your distribution network. It's not
                just about what you can gain; it's also about what you can
                offer. Share your expertise and resources with fellow
                entrepreneurs, fostering a thriving community of collaboration
                and growth. Let's come together to unlock a world of unlimited
                business opportunities. Don't miss out! Register now and be a
                part of this extraordinary event. See you there!
              </p>
            </Col>
            <Col md={5} className="imgCol">
              <Image src={image} className="homeImage1" alt="ee-image" fluid />
            </Col>
          </Row>
          <h3 className="homeBSubHead" style={{ margin: "30px 0" }}>
            Discover a world of possibilities with our Exclusive Empresas
            Business Summit.{" "}
          </h3>
          <Row>
            <Col md={6} className="imgCol2">
              <Image src={image2} className="homeImage2" alt="ee-image" fluid />
            </Col>
            <Col md={6}>
              <h3 className="infoHead1">Here's what you get:</h3>
              <ul>
                <li className="homeText2">
                  2-Day access to the Summit, packed with valuable insights and
                  opportunities.
                </li>
                <li className="homeText2">
                  Invaluable takeaways from proven entrepreneurs, experienced
                  business coaches, and thought leaders.
                </li>
                <li className="homeText2">
                  A lap of luxury with 5-Star accommodation on a twin-sharing
                  basis with delicious gourmet meals
                </li>
                <li className="homeText2">
                  Expand your business connections in creatively planned
                  networking sessions
                </li>
                <li className="homeText2">
                  Unwind and enjoy evening entertainment, making your experience
                  even more memorable.
                </li>
              </ul>
              <h3 style={{ margin: "0" }}>
                All this and more, exclusively priced at just{" "}
              </h3>
              <h4 className="infoHead1">Rs. 11,999</h4>
              <h5 className="amtTag"> (all-inclusive)</h5>
              <div className="btnContainer">
                {(() => {
                  if (
                    typeof user !== "undefined" &&
                    typeof user.username !== "undefined"
                  ) {
                    return (
                      <Button
                        className="btn--secondary"
                        style={{ marginTop: "10px", minWidth: "30%" }}
                        onClick={() => navigate("/ee/register")}
                      >
                        Register
                      </Button>
                    );
                  } else
                    return (
                      <Button
                        className="btn--secondary"
                        style={{ marginTop: "10px", minWidth: "30%" }}
                        onClick={() => navigate("/sign-up")}
                      >
                        Register
                      </Button>
                    );
                })()}
              </div>
            </Col>
          </Row>
          <h3 className="homeBSubHead">
            Don't miss this exceptional offer to elevate your business and
            career. Secure your spot now and join us for an extraordinary
            journey of growth and success at Exclusive Empresas.
          </h3>
        </div>
      </div>
      {/*
      <div className="homeContainer3">
        <div className="speakersContainer">
          <h2 className="infoHead2">Speakers</h2>

          <div className="speakersBox">
            <div class="bg-gradient-blur-wrapper-speaker">
              <div class="bg-gradient-blur-circle-3-speaker"></div>
              <div class="bg-gradient-blur-circle-2-speaker"></div>
              <div class="bg-gradient-blur-circle-1-speaker"></div>
            </div>
            <Row>
              <Col>
                <Image src={speakerVishal} fluid />
                <h3>Vishal Shah</h3>
                <p>Speaker 1 Info</p>
              </Col>
              <Col>
                <Image
                  src="https://images.unsplash.com/photo-1480429370139-e0132c086e2a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=688&q=80"
                  fluid
                />
                <h3>Speaker 2 Name</h3>
                <p>Speaker 2 Info</p>
              </Col>
              <Col>
                <Image
                  src="https://images.unsplash.com/photo-1480429370139-e0132c086e2a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=688&q=80"
                  fluid
                />
                <h3>Speaker 3 Name</h3>
                <p>Speaker 3 Info</p>
              </Col>
              <Col>
                <Image
                  src="https://images.unsplash.com/photo-1480429370139-e0132c086e2a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=688&q=80"
                  fluid
                />
                <h3>Speaker 4 Name</h3>
                <p>Speaker 4 Info</p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
      */}
    </div>
  );
};

export default EEHomeScreen;
