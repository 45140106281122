import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import eeLogo from "../images/eeLogo.jpg";
import "./Thankyou.css";

function EEThankYou2() {
  return (
    <div style={{ paddingTop: "15vh" }} className="thankYou">
      <div class="bg-gradient-blur-wrapper-left">
        <div class="bg-gradient-blur-circle-3-left"></div>
        <div class="bg-gradient-blur-circle-2-left"></div>
        <div class="bg-gradient-blur-circle-1-left"></div>
      </div>
      <div class="bg-gradient-blur-wrapper-right">
        <div class="bg-gradient-blur-circle-3-right"></div>
        <div class="bg-gradient-blur-circle-2-right"></div>
        <div class="bg-gradient-blur-circle-1-right"></div>
      </div>
      <div className="thankYouContainer">
        <Row className="justify-content-center">
          <Col style={{ textAlign: "center" }} className="col-md-auto">
            <img src={eeLogo} width="300px" />
            <h1 className="homeHead">EXCLUSIVE EMPRESAAS</h1>
            <h2 className="homeHeadTag">Opportunities Unlimited</h2>
            <div
              className="speakersBox"
              style={{ textAlign: "center", fontSize: "20px" }}
            >
              <h3>
                Thank you for completing your Final Registration Form with
                Exclusive Empresas!
              </h3>
              <h5 style={{ marginTop: "20px" }}>
                Your registration has progressed. Soon, we will verify your
                payment information with our records and notify your
                registration confirmation via email. This process might take 2
                or 3 working days. Please keep checking your email from{" "}
                <span style={{ fontWeight: "600" }}>strot@jsgif.co.in</span>{" "}
                with the subject "Thank you for completing your form 2
                information with Exclusive Empresas." Please check your spam
                folder if you do not find this email in your inbox within 2 to 3
                days.
              </h5>

              <h5>
                If you have any questions or need assistance, please feel free
                to reach out to us by calling -
              </h5>
              <h5>
                JSGian Kaplesh Desai - 9423012911 or JSGian Anshh Parekh -
                6369769821
              </h5>
            </div>
          </Col>
        </Row>
        <Row className="justify-content-center" style={{ margin: "1em 0" }}>
          <Col className="col-md-auto" style={{ textAlign: "center" }}>
            <Link to="/">
              <Button className="btn--three">Home</Button>
            </Link>
          </Col>
          {/* <Col className="col-md-auto">
          <Link to="/login">
            <Button className="btn--three">Sign In</Button>
          </Link>
        </Col> */}
        </Row>
      </div>
    </div>
  );
}

export default EEThankYou2;
