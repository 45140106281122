import React from "react";
import { Button, Row, Col, Table, Image } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import eeLogo from "../images/eeLogo.jpg";
import qr from "../images/jsg_rbl.jpg";

function EEThankYou1() {
  const { email } = useParams();

  return (
    <div className="thankYou">
      <div class="bg-gradient-blur-wrapper-left">
        <div class="bg-gradient-blur-circle-3-left"></div>
        <div class="bg-gradient-blur-circle-2-left"></div>
        <div class="bg-gradient-blur-circle-1-left"></div>
      </div>
      <div class="bg-gradient-blur-wrapper-right">
        <div class="bg-gradient-blur-circle-3-right"></div>
        <div class="bg-gradient-blur-circle-2-right"></div>
        <div class="bg-gradient-blur-circle-1-right"></div>
      </div>
      <div className="thankYouContainer">
        <Row className="justify-content-center">
          <Col style={{ textAlign: "center" }} className="col-md-auto">
            <img src={eeLogo} width="300px" />
            <h1 className="homeHead">EXCLUSIVE EMPRESAAS</h1>
            <h2 className="homeHeadTag">Opportunities Unlimited</h2>
            <div
              className="speakersBox"
              style={{ textAlign: "left", fontSize: "20px" }}
            >
              <h4>
                Thank you for registering with Exclusive Empresas. Your
                registration is subject to verification. To finalize your
                registration, please follow these steps:
              </h4>
              <ul style={{ textAlign: "justify", fontSize: "20px" }}>
                <li>
                  Make your payment of Rs. 11,999 via UPI by scanning the QR
                  Code given or via NEFT to the account details provided below:
                  {/* <Table style={{ margin: "10px 0" }} responsive="md" bordered>
                    <tbody className="tableBody">
                      <tr>
                        <td style={{ fontWeight: "800" }}>Name</td>
                        <td style={{ fontWeight: "600" }}>
                          JSG Int. Federation
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "800" }}>Bank Name</td>
                        <td style={{ fontWeight: "600" }}>Indian Bank</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "800" }}>Branch Name</td>
                        <td style={{ fontWeight: "600" }}>Prarthana Samaj</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "800" }}>Account Number</td>
                        <td style={{ fontWeight: "600" }}>417721000</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "800" }}>IFS Code</td>
                        <td style={{ fontWeight: "600" }}>IDIB000P049</td>
                      </tr>
                    </tbody>
                  </Table>
                  or
                  <br />
                  <Table style={{ margin: "10px 0" }} responsive="md" bordered>
                    <tbody className="tableBody">
                      <tr>
                        <td style={{ fontWeight: "800" }}>Name</td>
                        <td style={{ fontWeight: "600" }}>
                          Jain Social Groups International Federation
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "800" }}>Bank Name</td>
                        <td style={{ fontWeight: "600" }}>RBL Bank</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "800" }}>Branch Name</td>
                        <td style={{ fontWeight: "600" }}>Mandaveli</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "800" }}>Account Number</td>
                        <td style={{ fontWeight: "600" }}>400001042023</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: "800" }}>IFS Code</td>
                        <td style={{ fontWeight: "600" }}>RATN0000396</td>
                      </tr>
                    </tbody>
                  </Table> */}
                  <Row>
                    <Col md={8} lg={10}>
                      <Row>
                        <p style={{ margin: "0", fontWeight: "600" }}>
                          <span>Name:</span> JSG Int. Federation
                        </p>
                      </Row>
                      <Row>
                        <p style={{ margin: "0", fontWeight: "600" }}>
                          Bank Name: Indian Bank
                        </p>
                      </Row>
                      <Row>
                        <p style={{ margin: "0", fontWeight: "600" }}>
                          Branch Name: Prarthana Samaj
                        </p>
                      </Row>
                      <Row>
                        <p style={{ margin: "0", fontWeight: "600" }}>
                          Account Number: 417721000
                        </p>
                      </Row>
                      <Row>
                        <p style={{ margin: "0", fontWeight: "600" }}>
                          IFS Code: IDIB000P049
                        </p>
                      </Row>
                      <p style={{ margin: "5px 0" }}> or</p>
                      <p style={{ margin: "0", fontWeight: "600" }}>
                        Name: Jain Social Groups International Federation
                      </p>
                      <Row>
                        <p style={{ margin: "0", fontWeight: "600" }}>
                          Bank Name: RBL Bank
                        </p>
                      </Row>
                      <Row>
                        <p style={{ margin: "0", fontWeight: "600" }}>
                          Branch Name: Mandaveli
                        </p>
                      </Row>
                      <Row>
                        <p style={{ margin: "0", fontWeight: "600" }}>
                          Account Number: 400001042023
                        </p>
                      </Row>
                      <Row>
                        <p style={{ margin: "0", fontWeight: "600" }}>
                          IFS Code: RATN0000396
                        </p>
                      </Row>
                    </Col>
                    <Col md={8} lg={2}>
                      <Image src={qr} className="qrImg" />
                    </Col>
                  </Row>
                  Please note that the payment once made is non-refundable under
                  any circumstances.{" "}
                </li>
                <li>
                  Check your email from{" "}
                  <span style={{ fontWeight: "600" }}>strot@jsgif.co.in</span>{" "}
                  with the subject "Thank you for registering with Exclusive
                  Empresas." Please check your spam folder if you do not find
                  this email in your inbox.
                </li>
                <li>
                  Verify your email ID by clicking on the verification link in
                  the email.
                </li>
                <li>
                  Complete Form 2 to provide additional details, including your
                  Payment Reference Number (UTR No).
                </li>
              </ul>
              <h4 style={{ marginTop: "20px" }}>
                If you have any questions or need assistance, please feel free
                to reach out to us by calling
              </h4>
              <h4>
                JSGian Kaplesh Desai - 9423012911 or JSGian Anshh Parekh -
                6369769821
              </h4>
              <h5>We look forward to seeing you at the event!</h5>
              <h5>Best regards, Exclusive Empresas Team</h5>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "16px",
                  margin: "0",
                  fontStyle: "italic",
                }}
              >
                Haven't yet received email?
              </p>
              <Link to={`/resend-email-verification/${email}`}>
                <Button className="btn--primary">Resend Email</Button>
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default EEThankYou1;
