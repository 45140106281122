import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const ContacUs = () => {
  const user = useSelector((state) => state.authentication.user);
  const [submitting, setSubmitting] = useState(false);

  const [name, setname] = useState("");
  const [businessName, setbusinessName] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [detail, setdetail] = useState("");

  const navigate = useNavigate();

  async function FormSubmit(event) {
    event.preventDefault();
    setSubmitting(true);
    let formField = new FormData();

    const finalEmail =
      "president@jsgif.co.in,biren.shah@dikibi.com,caakshayjain@sbacompany.co.in,dj22jsgif@gmail.com,divyadityakothari25@gmail.com,kcdesai@gmail.com";

    formField.append("name", name);
    formField.append("businessName", businessName);
    formField.append("mobile", mobile);
    formField.append("email", email);
    formField.append("detail", detail);
    formField.append("emailList", finalEmail);

    await axios({
      method: "post",
      url: "/api/contact-us/",
      data: formField,
    })
      .then(function (response) {
        console.log(response);
        setShowModal(true);
      })
      .catch(function (error) {
        console.log(error);
        alert(
          "We encountered an error processing your request. Please try again later"
        );
      });

    setSubmitting(false);
  }

  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => {
    setShowModal(false);
    navigate("/");
  };

  return (
    <div style={{ paddingTop: "15vh" }}>
      <Container>
        <Row>
          <Col lg={12}>
            <h2>Contact Us</h2>
          </Col>
        </Row>

        <h4>
          We value your interest in Exclusive Empresas Business Summit. If you
          have any questions, inquiries, or would like to know more about the
          event, please feel free to reach out to us. Our team is dedicated to
          providing prompt and helpful assistance to ensure your experience with
          us is exceptional.
        </h4>
        <h4>
          Kindly fill out the contact form below with your details, and we'll
          get back to you at the earliest. Thank you for considering Exclusive
          Empresas, and we look forward to welcoming you to this enriching and
          inspiring event. Let's embark on this journey of endless possibilities
          together!
        </h4>

        <Form onSumbit={FormSubmit}>
          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  required
                  name="name"
                  value={name}
                  placeholder="Respondant's Full name"
                  onChange={(e) => setname(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Business Name</Form.Label>
                <Form.Control
                  name="businessName"
                  value={businessName}
                  placeholder="Respondant's Business Name"
                  onChange={(e) => setbusinessName(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  required
                  name="email"
                  type="email"
                  value={email}
                  placeholder="Respondant's Email Address"
                  onChange={(e) => setemail(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Mobile/Whatsapp Number</Form.Label>
                <Form.Control
                  required
                  name="mobile"
                  value={mobile}
                  placeholder="Respondant's Mobile/Whatsapp Number"
                  onChange={(e) => setmobile(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg xs={12}>
              <Form.Group>
                <Form.Label>What would you like to know from us?</Form.Label>
                <Form.Control
                  as="textarea"
                  required
                  name="detail"
                  value={detail}
                  onChange={(e) => setdetail(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          {submitting ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Submitting Please Wait</Tooltip>
              }
            >
              <Button className="btn--outline--dark" disabled>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Submiting
              </Button>
            </OverlayTrigger>
          ) : (
            <Button className="btn--outline--dark" type="submit">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Submit
            </Button>
          )}
        </Form>
      </Container>
      <Modal centered show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Inquiry Received</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
          <p>
            Thank you for sending your inquiry. We have received it and will
            revert soon.
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button className="btn--four" onClick={handleModalClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ContacUs;
