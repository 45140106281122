import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import { Link, useParams, useNavigate } from "react-router-dom";
import eeLogo from "../images/eeLogo.jpg";

function EEAlreadyRegistered() {
  const navigate = useNavigate();
  return (
    <div className="thankYou">
      <div class="bg-gradient-blur-wrapper-left">
        <div class="bg-gradient-blur-circle-3-left"></div>
        <div class="bg-gradient-blur-circle-2-left"></div>
        <div class="bg-gradient-blur-circle-1-left"></div>
      </div>
      <div class="bg-gradient-blur-wrapper-right">
        <div class="bg-gradient-blur-circle-3-right"></div>
        <div class="bg-gradient-blur-circle-2-right"></div>
        <div class="bg-gradient-blur-circle-1-right"></div>
      </div>
      <div className="thankYouContainer">
        <Row className="justify-content-center">
          <Col style={{ textAlign: "center" }} className="col-md-auto">
            <img src={eeLogo} width="300px" />
            <h1 className="homeHead">EXCLUSIVE EMPRESAAS</h1>
            <h2 className="homeHeadTag">Opportunities Unlimited</h2>
            <div
              className="speakersBox"
              style={{ textAlign: "center", fontSize: "20px" }}
            >
              <h4>
                You have already Signed Up for Exclusive Empresaas using this
                email id.
              </h4>
              <h4>You can login by clicking on the button below</h4>
              <Button
                className="btn--secondary"
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
              <h4 style={{ marginTop: "20px" }}>
                If you have any questions or need assistance, please feel free
                to reach out to us by calling
              </h4>
              <h4>
                JSGian Kaplesh Desai - 9423012911 or JSGian Anshh Parekh -
                6369769821
              </h4>
              <h5>We look forward to seeing you at the event!</h5>
              <h5>Best regards, Exclusive Empresas Team</h5>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default EEAlreadyRegistered;
