import React from "react";
import "./Footer.css";
import { Row, Col } from "react-bootstrap";

function Footer() {
  return (
    <div className="footer-section">
      <div className="footerContainer">
        <Row>
          <p className="footerText">
            Address: 4-O/P, Vijay Chambers, Opp. Dreamland Cinema, Tribhuvan
            Road, Mumbai - 400 004.
          </p>
          <p className="footerText">Phone: 022-23870724</p>
          <p className="footerText">Email: office@jsgif.co.in</p>
          <p className="footerText" style={{ margin: "0", marginTop: "20px" }}>
            Follow Us On
          </p>
          <div className="footerSocials">
            <a
              className="footerSocialIcons"
              target="_blank"
              href="https://www.youtube.com/@jsgint.federation7490"
            >
              <i className="fa-brands fa-youtube"></i>
            </a>
            <a
              className="footerSocialIcons"
              target="_blank"
              href="https://www.facebook.com/profile.php?id=100067976321724"
            >
              <i className="fa-brands fa-facebook"></i>
            </a>
            <a
              className="footerSocialIcons"
              target="_blank"
              href="https://www.instagram.com/jsgintfederation/"
            >
              <i className="fa-brands fa-instagram"></i>
            </a>
          </div>
          <p className="footerText">
            © 2023 Jain Social Groups International Federation. All rights
            reserved. Exclusive Empresas Business Summit is a registered event
            of JSGIF, Strot
          </p>
        </Row>
      </div>
    </div>
  );
}

export default Footer;
