import React, { useState, useEffect } from "react";
import {
  Container,
  Navbar,
  Nav,
  NavDropdown,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import "./Header.css";
import { LinkContainer } from "react-router-bootstrap";
import logo from "../images/logo.png";
import strot from "../images/strot.png";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../actions/user.actions";
import { useNavigate } from "react-router-dom";

function Header() {
  const navigate = useNavigate();

  const signoutSession = () => {
    sessionStorage.removeItem("user");
    localStorage.removeItem("user");
    dispatch(userActions.login({}));
    navigate("/");
  };

  const dispatch = useDispatch();
  const user = useSelector((state) => state.authentication.user);

  let initialsString = ""; // Initialize initialsString outside the condition

  if (user && user.full_name) {
    const words = user.full_name.split(" ");
    const initials = words.map((word) => word.charAt(0).toUpperCase());
    initialsString = initials.join("");
  }

  return (
    <div>
      <Navbar
        sticky="top"
        collapseOnSelect
        className="navbarBg"
        expand="lg"
        variant="dark"
      >
        <Container>
          <LinkContainer to="/ee">
            <Navbar.Brand>
              <img
                alt="Jsgif"
                src={logo}
                className="d-inline-block align-top brandImage"
              />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="m-auto">
              <LinkContainer to="/ee">
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/about-strot">
                <Nav.Link>Strot</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/contact-us">
                <Nav.Link>Contact Us</Nav.Link>
              </LinkContainer>

              {(() => {
                if (
                  typeof user !== "undefined" &&
                  typeof user.username !== "undefined"
                ) {
                  return (
                    <NavDropdown
                      title={<>{initialsString}</>}
                      id="collasible-nav-dropdown"
                      align={{ lg: "end" }}
                    >
                      <NavDropdown.Header>
                        <i
                          style={{ marginRight: "5px" }}
                          className="fa-solid fa-user"
                        ></i>
                        {user.full_name}
                      </NavDropdown.Header>
                      <NavDropdown.Divider></NavDropdown.Divider>
                      <LinkContainer to="/my-profile">
                        <NavDropdown.Item>Profile</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/change-password">
                        <NavDropdown.Item>Change Password</NavDropdown.Item>
                      </LinkContainer>
                      <LinkContainer to="/">
                        <span style={{ textDecoration: "underline" }}>
                          <NavDropdown.Item onClick={signoutSession}>
                            Sign Out
                          </NavDropdown.Item>
                        </span>
                      </LinkContainer>
                    </NavDropdown>
                  );
                } else
                  return (
                    <LinkContainer to="/login">
                      <Nav.Link>Login</Nav.Link>
                    </LinkContainer>
                  );
              })()}
            </Nav>
          </Navbar.Collapse>
          <Nav>
            <LinkContainer to="/ee">
              <Navbar.Brand>
                <img
                  alt="Jsgif"
                  src={strot}
                  height="90px"
                  className="d-inline-block align-top brandImage"
                />{" "}
              </Navbar.Brand>
            </LinkContainer>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;
