import React, { useEffect, useState } from "react";
import "./About.css";
import SecurePageSection from "./../SecurePageSection";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Row, Col } from "react-bootstrap";

function About() {
  const user = useSelector((state) => state.authentication.user);
  const location = useLocation();

  return (
    <div className="about">
      <div className="aboutContainer">
        <h1>WHAT IS STROT?</h1>
        <div class="bg-gradient-blur-wrapper-about">
          <div class="bg-gradient-blur-circle-3-about"></div>
          <div class="bg-gradient-blur-circle-2-about"></div>
          <div class="bg-gradient-blur-circle-1-about"></div>
        </div>
        <div className="aboutBox">
          <p className="aboutText">
            JSGIF Strot is a committee powered by JSGIF that focuses on
            supporting and fostering the growth of startups within Jain Social
            Groups. It will be a team of individuals who are passionate about
            entrepreneurship and have expertise in various aspects of business
            and startups. The aim is to create a supportive ecosystem that
            encourages and nurtures the development of innovative ideas and new
            businesses within the Jain community. The committee will organize
            programs, events, workshops, and seminars tailored to the needs of
            Jain startups.
          </p>
          <p className="aboutText">
            These activities can include business planning sessions, skill
            development workshops, networking events with investors and industry
            experts, and mentorship programs. The committee will also
            collaborate with other organizations, universities, or incubators to
            provide additional resources and support to Jain startups.
          </p>
          <p className="aboutText">
            The startup committee will also play a role in connecting
            entrepreneurs with potential investors or funding sources, providing
            access to relevant industry networks, and offering guidance on
            legal, regulatory, or financial matters specific to startups.
            Existing businesses looking to ramp up their operations and in need
            of rebranding or funding or legal or accounting assistance or to
            access new clients or suppliers, JSGIF is the door to knock on.
          </p>
        </div>
        <h1 className="aboutHead2">Organisations supporting Strot?</h1>
        <div className="aboutOrgContainer">
          <div className="aboutOrgBox">
            <p className="aboutOrgs" style={{ textAlign: "right" }}>
              Renaissance University,
              <br /> Indore.
            </p>
          </div>
          <div className="aboutOrgBox" style={{ borderLeft: "1px solid #fff" }}>
            <p className="aboutOrgs">
              S B A & Company, <br />
              Chartered Accountants,
              <br /> Indore.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
