import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import eeLogo from "../images/eeLogo.jpg";

function EERegistered() {
  return (
    <div style={{ paddingTop: "15vh" }}>
      <div class="bg-gradient-blur-wrapper-left">
        <div class="bg-gradient-blur-circle-3-left"></div>
        <div class="bg-gradient-blur-circle-2-left"></div>
        <div class="bg-gradient-blur-circle-1-left"></div>
      </div>
      <div class="bg-gradient-blur-wrapper-right">
        <div class="bg-gradient-blur-circle-3-right"></div>
        <div class="bg-gradient-blur-circle-2-right"></div>
        <div class="bg-gradient-blur-circle-1-right"></div>
      </div>
      <Row className="justify-content-center">
        <Col style={{ textAlign: "center" }} className="col-md-auto">
          <img src={eeLogo} width="300px" />
          <h1 className="homeHead">EXCLUSIVE EMPRESAAS</h1>
          <h2 className="homeHeadTag">Opportunities Unlimited</h2>
          <h3>You have Already Registered for Exclusive Empresaas!</h3>
          <h3>Please use a different email id and register again</h3>
          <h4 style={{ marginTop: "20px" }}>
            If you have any questions or need assistance, please feel free to
            reach out to us by calling
          </h4>
          <h4>JSGian Kaplesh Desai - 9423012911</h4>
          <h4>JSGian Anshh Parekh - 6369769821</h4>
          <h5>Best regards, Exclusive Empresas Team</h5>
        </Col>
      </Row>
      <Row className="justify-content-center" style={{ marginTop: "1em" }}>
        <Col className="col-md-auto" style={{ textAlign: "center" }}>
          <Link to="/sign-up">
            <Button className="btn--three">Register</Button>
          </Link>
        </Col>
      </Row>
    </div>
  );
}

export default EERegistered;
