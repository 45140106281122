import React, { useEffect, useRef, useState } from "react";
import "./SignUp.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  Button,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";

import ReCAPTCHA from "react-google-recaptcha";
import MyRecaptchaComponent from "./MyRecaptchaComponent";

function SignUp() {
  const [validated, setValidated] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const initialValues = {
    full_name: "",
    dob: "",
    email: "",
    gender: "",
    password1: "",
    password2: "",
    contactNumber: "",
    whatsappNumber: "",
    isWhatsapp: true,
    isJsg: false,
    regName: "",
    grpName: "1",
    businessName: "",
    dealingIn: "",
    // Add other fields and their initial values as needed
  };

  const validationSchema = Yup.object().shape({
    isJsg: Yup.boolean(),
    regName: Yup.string(),
    grpName: Yup.string(),
    full_name: Yup.string().required("Full Name is required"),
    gender: Yup.string().required("Gender is required"),
    contactNumber: Yup.string().required("Mobile Number is required"),
    email: Yup.string().email("Invalid email").required("Email is required"),
    dob: Yup.date().required("Date of Birth is required"),
    password1: Yup.string()
      .required("Password is required")
      .min(8, "Password must be at least 8 characters"),
    password2: Yup.string()
      .required("Please re-enter the password")
      .oneOf([Yup.ref("password1"), null], "Passwords must match"),
    businessName: Yup.string().required("Business Name is required"),
    dealingIn: Yup.string().required("Business Type is required"),
    isWhatsapp: Yup.boolean(),
    whatsappNumber: Yup.string(),
  });

  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const formField = new FormData();

    // Convert the values object to FormData
    Object.keys(values).forEach((key) => {
      formField.append(key, values[key]);
    });

    // Append whatsappNumber based on isWhatsapp
    if (values.isWhatsapp) {
      formField.append("whatsappNumber", values.contactNumber);
    } else {
      formField.append("whatsappNumber", values.whatsappNumber);
    }

    formField.append("password", values.password1);

    if (values.isJsg === false) {
      formField.append("regName", "");
      formField.append("grpName", "");
    } else {
      formField.append("regName", values.regName);
      formField.append("grpName", values.grpName);
    }
    formField.append("accessGroup", "strotUser");

    await axios({
      method: "post",
      url: "/user/create/",
      data: formField,
    })
      .then(function (response) {
        console.log(response.data);
        if (response.data.error === "Already Registered") {
          navigate(`/already-registered`);
        } else {
          navigate(`/sign-up/thank-you/${values.email}`);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    setSubmitting(false);
  };

  const siteKey = "6LeQ3jgnAAAAALZFS-nrNyhc_yq_zefKKd2_8_OB";

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    await axios({
      method: "get",
      url: "https://jsgif.co.in/user/region/",
    })
      .then(function (response) {
        setRegions(
          response.data.sort((a, b) => a.region.localeCompare(b.region))
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  const [groups, setGroups] = useState([]);
  const fetchGroups = async (regName) => {
    try {
      const response = await axios.get(
        `https://jsgif.co.in/user/${regName}/groups/`
      );
      const sortedGroups = response.data.sort((a, b) =>
        a.group.localeCompare(b.group)
      );
      setGroups(sortedGroups);
    } catch (error) {
      // Handle errors if needed
    }
  };

  // useEffect(() => {
  //   if (regName) {
  //     fetchGroups(regName);
  //   }
  // }, [regName]);

  const [isVerified, setIsVerified] = useState(false);

  const handleRecaptchaChange = (value) => {
    setIsVerified(!!value);
  };

  // const handleIsJsg = (e) => {
  //   setisJsg(e.target.checked);
  //   if (e.target.checked === false) {
  //     setgrpName("");
  //     setregName("");
  //   }
  // };

  return (
    <div className="signup">
      <div className="signupContainer">
        <Row>
          <Col md={2} style={{ padding: "0" }}>
            <div className="signupLeft">
              <div className="signupLeftContainer"></div>
            </div>
          </Col>
          <Col md={4} className="signUpFormContainer">
            <div className="signupForm">
              <h1>Sign Up</h1>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ handleSubmit, handleChange, values, errors, touched }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <Row>
                      <Col xs={12} md={6}>
                        <Form.Group>
                          <Form.Label>Full Name</Form.Label>
                          <Form.Control
                            name="full_name"
                            value={values.full_name}
                            onChange={handleChange}
                            isInvalid={touched.full_name && !!errors.full_name}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.full_name}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col xs={12} md={6}>
                        <Form.Group>
                          <Form.Label>Date of Birth</Form.Label>
                          <Form.Control
                            type="date"
                            name="dob"
                            value={values.dob}
                            onChange={handleChange}
                            isInvalid={touched.dob && !!errors.dob}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.dob}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} md={6}>
                        <Form.Group>
                          <Form.Label>Email Address</Form.Label>
                          <Form.Control
                            type="email"
                            name="email"
                            value={values.email}
                            placeholder="Email Address"
                            onChange={handleChange}
                            isInvalid={touched.email && !!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col xs={12} md={6}>
                        <Form.Group>
                          <Form.Label>Gender</Form.Label>
                          <Form.Select
                            name="gender"
                            value={values.gender}
                            onChange={handleChange}
                            isInvalid={touched.gender && !!errors.gender}
                          >
                            <option>Select Your Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.gender}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} md={6}>
                        <Form.Group>
                          <Form.Label>Password</Form.Label>
                          <Form.Control
                            type="password"
                            name="password1"
                            value={values.password1}
                            placeholder="Minimum 8 Characters"
                            onChange={handleChange}
                            isInvalid={touched.password1 && !!errors.password1}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.password1}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col xs={12} md={6}>
                        <Form.Group>
                          <Form.Label>Re-type Password</Form.Label>
                          <Form.Control
                            type="password"
                            name="password2"
                            value={values.password2}
                            placeholder="Minimum 8 Characters"
                            onChange={handleChange}
                            isInvalid={touched.password2 && !!errors.password2}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.password2}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs={12} md={6}>
                        <Form.Group>
                          <Form.Label>Mobile Number</Form.Label>
                          <Form.Control
                            name="contactNumber"
                            value={values.contactNumber}
                            placeholder="Mobile Number"
                            onChange={handleChange}
                            isInvalid={
                              touched.contactNumber && !!errors.contactNumber
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.contactNumber}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      {values.isWhatsapp ? (
                        <Col xs={12} md={6}>
                          <Form.Group>
                            <Form.Label>Whatsapp Number</Form.Label>
                            <Form.Control
                              name="whatsapp"
                              value={values.contactNumber}
                              placeholder="Whatsapp Number"
                              onChange={handleChange}
                              isInvalid={
                                touched.whatsappNumber &&
                                !!errors.whatsappNumber
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.whatsappNumber}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      ) : (
                        <Col xs={12} md={6}>
                          <Form.Group>
                            <Form.Label>Whatsapp Number</Form.Label>
                            <Form.Control
                              name="whatsapp"
                              value={values.whatsappNumber}
                              placeholder="Whatsapp Number"
                              onChange={handleChange}
                              isInvalid={
                                touched.whatsappNumber &&
                                !!errors.whatsappNumber
                              }
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.whatsappNumber}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      )}
                    </Row>

                    <Row>
                      <Col xs={12}>
                        <Form.Group>
                          <Form.Check
                            type="checkbox"
                            label={`Is the whatsapp Number same as Mobile Number?`}
                            name="isWhatsapp"
                            checked={values.isWhatsapp}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    <Row style={{ marginTop: "20px" }}>
                      <Col xs={12} md={6}>
                        <Form.Group>
                          <Form.Check
                            type="checkbox"
                            label={`Are you a JSG Member?`}
                            name="isJsg"
                            checked={values.isJsg}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>

                    {values.isJsg ? (
                      <>
                        <Row>
                          <Col xs={12} md={6}>
                            <Form.Group>
                              <Form.Label>Region Name</Form.Label>
                              <Form.Select
                                name="regName"
                                value={values.regName}
                                onChange={(e) => {
                                  handleChange(e);
                                  fetchGroups(e.target.value);
                                }}
                                isInvalid={touched.regName && !!errors.regName}
                              >
                                <option>Select your Region</option>
                                {regions.map((region, index) => (
                                  <option
                                    key={index}
                                    style={{ textTransform: "capitalize" }}
                                    value={region.region.toLowerCase()}
                                  >
                                    {region.region.charAt(0).toUpperCase() +
                                      region.region.slice(1).toLowerCase()}
                                  </option>
                                ))}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                {errors.regName}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>

                          <Col xs={12} md={6}>
                            <Form.Group>
                              <Form.Label>Group Name</Form.Label>
                              <Form.Select
                                name="grpName"
                                value={values.grpName}
                                onChange={handleChange}
                                isInvalid={touched.grpName && !!errors.grpName}
                              >
                                <option>Select your Group</option>
                                {groups.map((group, index) => (
                                  <option
                                    key={index}
                                    style={{ textTransform: "capitalize" }}
                                    value={group.group.toLowerCase()}
                                  >
                                    {group.group.charAt(0).toUpperCase() +
                                      group.group.slice(1).toLowerCase()}
                                  </option>
                                ))}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                {errors.grpName}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <>
                        <Row>
                          <Col xs={12} md={6}>
                            <Form.Group>
                              <Form.Label>Region Name</Form.Label>
                              <Form.Select
                                name="regName"
                                value={values.regName}
                                onChange={handleChange}
                                disabled
                              >
                                <option>Select your Region</option>
                                {regions.map((region, index) => (
                                  <option
                                    key={index}
                                    style={{ textTransform: "capitalize" }}
                                    value={region.region.toLowerCase()}
                                  >
                                    {region.region.charAt(0).toUpperCase() +
                                      region.region.slice(1).toLowerCase()}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </Col>

                          <Col xs={12} md={6}>
                            <Form.Group>
                              <Form.Label>Group Name</Form.Label>
                              <Form.Select
                                name="grpName"
                                value={values.grpName}
                                onChange={handleChange}
                                disabled
                              >
                                <option>Select your Group</option>
                                {groups.map((group, index) => (
                                  <option
                                    key={index}
                                    style={{ textTransform: "capitalize" }}
                                    value={group.group.toLowerCase()}
                                  >
                                    {group.group.charAt(0).toUpperCase() +
                                      group.group.slice(1).toLowerCase()}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </Col>
                        </Row>
                      </>
                    )}

                    <Row>
                      <Col xs={12} md={6}>
                        <Form.Group>
                          <Form.Label>Business Name</Form.Label>
                          <Form.Control
                            name="businessName"
                            value={values.businessName}
                            placeholder="Name of your business"
                            onChange={handleChange}
                            isInvalid={
                              touched.businessName && !!errors.businessName
                            }
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.businessName}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>

                      <Col xs={12} md={6}>
                        <Form.Group>
                          <Form.Label>Dealing In</Form.Label>
                          <Form.Control
                            name="dealingIn"
                            value={values.dealingIn}
                            placeholder="Type of your business"
                            onChange={handleChange}
                            isInvalid={touched.dealingIn && !!errors.dealingIn}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.dealingIn}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>

                    {/* <MyRecaptchaComponent /> */}

                    <Row>
                      <Col xs={12}>
                        <Button
                          className="btn--outline--dark"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                          {isSubmitting ? "Submitting..." : "Submit"}
                        </Button>
                        <Link to="/login" className="loginLink">
                          <p>Already a User? Login</p>
                        </Link>
                      </Col>
                    </Row>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>

          <Col md={6} style={{ padding: "0" }}>
            <div className="signupRight">
              <div className="signupRightContainer">
                <h1 className="signUpHead1">JSGIF Strot</h1>
                <h2 className="signUpHead2">Opportunities Unlimited</h2>
                <h3 className="signUpHead3">
                  One Account for access to all the future events
                </h3>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default SignUp;
