import "./App.css";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./screens/Layout";
import EEHomeScreen from "./screens/EEHomeScreen";
import About from "./screens/About";
import EERegister from "./screens/EERegister";
import SignUp from "./screens/SignUp";
import Login from "./screens/Login";
import ForgotPassword from "./screens/ForgotPassword";
import EEResponse from "./screens/EEResponse";
import HomeScreen from "./screens/HomeScreen";
import EERegistered from "./screens/EERegistered";
import EEThankYou1 from "./screens/EEThankYou1";
import EEThankYou2 from "./screens/EEThankYou2";
import EmailVerification from "./screens/EmailVerification";
import ChangePassword from "./screens/ChangePassword";
import ResendVerificationEmail from "./screens/ResendVerificationEmail";
import EEAlreadyRegistered from "./screens/EEAlreadyRegistered";
import MyProfile from "./screens/MyProfile";
import ContacUs from "./screens/ContactUs";
import EEDay from "./screens/EEDay";
import EEThankYou3 from "./screens/EEThankYou3";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<Layout />} exact>
            <Route path="" element={<HomeScreen />} />
            <Route path="ee" element={<EEHomeScreen />} />
            <Route path="about-strot" element={<About />} />
            <Route path="sign-up" element={<SignUp />} />
            <Route path="sign-up/thank-you/:email" element={<EEThankYou1 />} />
            <Route path="login" element={<Login />} />
            <Route path="verify-email/:token" element={<EmailVerification />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route
              path="resend-email-verification/:email"
              element={<ResendVerificationEmail />}
            />
            <Route
              path="already-registered"
              element={<EEAlreadyRegistered />}
            />
            <Route path="my-profile" element={<MyProfile />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="ee/register" element={<EERegister />} />
            <Route path="ee/1-day/register" element={<EEDay />} />
            <Route path="contact-us" element={<ContacUs />} />
            <Route path="ee/form-2/thank-you" element={<EEThankYou2 />} />
            <Route path="ee/1-day/thank-you" element={<EEThankYou3 />} />
            <Route path="ee/response/:id" element={<EEResponse />} />
            <Route path="ee/registered/" element={<EERegistered />} />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
