import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Button, Container, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import "./EERegister.css";

import { Formik, useFormikContext } from "formik";
import * as Yup from "yup";
import SecurePageSection from "../SecurePageSection";
// import SecurePageSection from "../../../SecurePageSection";

const EERegister = () => {
  const user = useSelector((state) => state.authentication.user);

  const [regEmails, setRegEmails] = useState(false);
  const [isSubmitting, setisSubmitting] = useState(false);

  const [photo, setphoto] = useState(null);
  const [aadharFront, setaadharFront] = useState(null);
  const [aadharback, setaadharback] = useState(null);

  useEffect(() => {
    const checkFormStatus = async () => {
      try {
        const response = await axios.get(`/api/form/user/ee/application/${user.user_id}`, {});
        console.log(response.data);
        if (response.data.isFormFilled === true) {
          navigate("/ee/registered/");
        }
      } catch (error) {
        console.error("Error checking form status:", error);
      }
    };

    checkFormStatus();
  }, []);

  // const [regConvenor, setRegConvenor] = useState("");
  const navigate = useNavigate();

  const fetchRegEmails = async (regName) => {
    const chairmanResponse = await axios.get(`https://jsgif.co.in/api/data/${regName}/region-ob/emails/`);
    const chairmanEmail = chairmanResponse.data.filter((item) => item.post === "chairman").map((item) => item.email);
    setRegEmails(chairmanEmail);
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    setisSubmitting(true);
    const formField = new FormData();
    // const finalEmail = values.email;
    const finalEmail =
      "president@jsgif.co.in,biren.shah@dikibi.com,caakshayjain@sbacompany.co.in,dj22jsgif@gmail.com,divyadityakothari25@gmail.com,kcdesai@gmail.com," +
      values.email;

    Object.keys(values).forEach((key) => {
      if (!(values[key] instanceof File)) {
        formField.append(key, values[key]);
      }
    });

    if (photo !== null) {
      formField.append("photo", photo);
    }
    if (aadharFront !== null) {
      formField.append("aadharFront", aadharFront);
    }
    if (aadharback !== null) {
      formField.append("aadharback", aadharback);
    }

    formField.append("user", user?.user_id);
    formField.append("emailList", finalEmail);
    formField.append("paymentAmount", "Rs. 11,999");

    await axios({
      method: "post",
      url: "/api/form/ee/application/",
      data: formField,
    })
      .then(function (response) {
        localStorage.removeItem("formData");
        setTimeout(() => {
          navigate("/ee/form-2/thank-you");
        }, 100);
      })
      .catch(function (error) {
        console.log(error);
      });

    setisSubmitting(false);
  };

  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => {
    setShowModal(false);
    navigate("/");
  };

  const [userData, setuserData] = useState("");
  const fetchUserData = async () => {
    await axios({
      method: "get",
      url: `/user/user/${user.user_id}`,
    })
      .then(function (response) {
        console.log(response.data);
        setuserData(response.data);
        setInitialValues({
          full_name: response.data.full_name || "",
          email: response.data.email || "",
          dob: response.data.dob || "",
          gender: response.data.gender || "",
          contactNumber: response.data.contactNumber || "",
          whatsappNumber: response.data.whatsappNumber || "",
          regName: response.data.regName || "",
          grpName: response.data.grpName || "",
          businessName: response.data.businessName || "",
          dealingIn: response.data.dealingIn || "",
          isJsg: response.data.isJsg ? "Yes" : "No",
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchUserData();
  }, []);

  // useEffect(() => {
  //   const storedValues = localStorage.getItem("formData");
  //   if (storedValues && storedValues.email === userData.email) {
  //     const parsedStoredValues = JSON.parse(storedValues);
  //     const mergedValues = { ...initialValues, ...parsedStoredValues };
  //     setInitialValues(mergedValues);
  //   }
  // }, [userData]);

  const [initialValues, setInitialValues] = useState({
    full_name: "",
    email: "",
    dob: "",
    gender: "",
    contactNumber: "",
    whatsappNumber: "",
    isJsg: "",
    regName: "",
    grpName: "",
    residentialAddress1: "",
    residentialAddress2: "",
    residentialCity: "",
    residentialPin: "",
    businessName: "",
    dealingIn: "",
    businessCategory: [],
    businessAddress1: "",
    businessAddress2: "",
    businessCity: "",
    businessPin: "",
    businessPhone: "",
    businessGst: "",
    businessNumIndia: "",
    businessNumAbroad: "",
    businessIncorporated: "",
    businessSummit: "",
    paymentAmount: "Rs. 11,999",
    paymentDate: "",
    paymentBank: "",
    paymentBranch: "",
    paymentUTR: "",
    idType: "",
    aadharFront: null,
    aadharback: null,
    photo: null,
  });

  const validationSchema = Yup.object({
    full_name: Yup.string().required("Full Name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    dob: Yup.date().required("Date of Birth is required"),
    gender: Yup.string(),
    contactNumber: Yup.string().required("Contact Number is required"),
    whatsappNumber: Yup.string().required("Whatsapp Number is required"),
    regName: Yup.string(),
    grpName: Yup.string(),
    residentialAddress1: Yup.string().min(1, "Residential Address Line 1 is required"),
    residentialAddress2: Yup.string(),
    residentialCity: Yup.string(),
    residentialPin: Yup.string(),
    businessName: Yup.string().required("Business Name is required"),
    dealingIn: Yup.string().required("Dealing In is required"),
    businessCategory: Yup.array().required("Select atleast one category").required("Select at least one category."),
    businessAddress1: Yup.string().min(1, "Business Address Line 1 is required"),
    businessAddress2: Yup.string(),
    businessCity: Yup.string().required("Business City Name is required"),
    businessPin: Yup.string().required("Business Pincode is required"),
    businessPhone: Yup.string(),
    businessGst: Yup.string(),
    businessNumIndia: Yup.string(),
    businessNumAbroad: Yup.string(),
    businessIncorporated: Yup.string(),
    businessSummit: Yup.string(),
    paymentAmount: Yup.string(),
    paymentDate: Yup.date().required("Date of Payment is required"),
    paymentBank: Yup.string().required("Bank Name is required"),
    paymentBranch: Yup.string(),
    paymentUTR: Yup.string()
      .required()
      .min(12, "Payment UTR must be at least 12 characters")
      .max(26, "Payment UTR must not exceed 26 characters"),
    idType: Yup.string(),
    photo: Yup.mixed().required("Your photo is required").nullable(),
    aadharFront: Yup.mixed().required("Identity Page Front is required").nullable(),
    aadharback: Yup.mixed().nullable(),
  });

  const valuesRef = useRef(null);

  useEffect(() => {
    const valuesFunction = () => {
      console.log(valuesRef.current.values);
    };

    valuesFunction();
  }, [valuesRef]);

  const handleSave = (values) => {
    localStorage.setItem("formData", JSON.stringify(values));
    // alert("Form data saved successfully!");
  };

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    await axios({
      method: "get",
      url: "https://jsgif.co.in/user/region/",
    })
      .then(function (response) {
        setRegions(
          response.data.sort((a, b) => a.region.localeCompare(b.region))
        );
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  const [groups, setGroups] = useState([]);
  const fetchGroups = async (regName) => {
    try {
      const response = await axios.get(
        `https://jsgif.co.in/user/${regName}/groups/`
      );
      const sortedGroups = response.data.sort((a, b) =>
        a.group.localeCompare(b.group)
      );
      setGroups(sortedGroups);
    } catch (error) {
      // Handle errors if needed
    }
  };

  return (
    <div className="eeRegister">
      {/* <SecurePageSection accessableUsers={"admin,strotUser"} /> */}
      <div class="bg-gradient-blur-wrapper-left-form">
        <div class="bg-gradient-blur-circle-3-left"></div>
        <div class="bg-gradient-blur-circle-2-left"></div>
        <div class="bg-gradient-blur-circle-1-left"></div>
      </div>
      <div class="bg-gradient-blur-wrapper-right-form">
        <div class="bg-gradient-blur-circle-3-right"></div>
        <div class="bg-gradient-blur-circle-2-right"></div>
        <div class="bg-gradient-blur-circle-1-right"></div>
      </div>

      <div class="bg-gradient-blur-wrapper-left-form2">
        <div class="bg-gradient-blur-circle-3-right"></div>
        <div class="bg-gradient-blur-circle-2-right"></div>
        <div class="bg-gradient-blur-circle-1-right"></div>
      </div>

      <div className="eeFormContainer">
        <h1>Final Registration Form</h1>
        <h2 style={{ textAlign: "center" }}>Primary Information</h2>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          innerRef={valuesRef}
        >
          {({ handleSubmit, handleChange, values, errors, setFieldValue }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col lg={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control
                      name="full_name"
                      value={values.full_name}
                      placeholder="Full Name"
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.full_name}
                    />
                    <Form.Control.Feedback type="invalid">{errors.full_name}</Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      name="email"
                      value={values.email}
                      placeholder="Email Address"
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control
                      type="date"
                      name="dob"
                      value={values.dob}
                      placeholder="Email Address"
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.dob}
                    />
                    <Form.Control.Feedback type="invalid">{errors.dob}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col lg={4} xs={12}>
                <Form.Group>
                          <Form.Label>Gender</Form.Label>
                          <Form.Select
                            name="gender"
                            value={values.gender}
                            onChange={handleChange}
                            isInvalid={!!errors.gender}
                          >
                            <option>Select Your Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            {errors.gender}
                          </Form.Control.Feedback>
                        </Form.Group> 
                </Col>

                <Col lg={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      name="contactNumber"
                      value={values.contactNumber}
                      placeholder="Mobile Number"
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.contactNumber}
                    />
                    <Form.Control.Feedback type="invalid">{errors.contactNumber}</Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Whatsapp Number</Form.Label>
                    <Form.Control
                      name="contactNumber"
                      value={values.whatsappNumber}
                      placeholder="Whatsapp Number"
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.whatsappNumber}
                    />
                    <Form.Control.Feedback type="invalid">{errors.whatsappNumber}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row style={{ marginTop: "20px" }}>
                      <Col xs={12} md={4}>
                        <Form.Group>
                              <Form.Label>Are you a JSG Member?</Form.Label>
                          <Form.Select
                            name="isJsg"
                            checked={values.isJsg}
                      onChange={(e)=>{handleChange(e); handleSave(values)}}
                      >
                            <option>Selece one option</option>
                            <option value="true">Yes</option>
                            <option value="false">No</option>

                          </Form.Select>
                        </Form.Group>
                      </Col>

                    {values.isJsg === "true" ? (
                      <>
                          <Col xs={12} md={4}>
                            <Form.Group>
                              <Form.Label>Region Name</Form.Label>
                              <Form.Select
                                name="regName"
                                value={values.regName}
                                onChange={(e) => {
                                  handleChange(e);
                                  fetchGroups(e.target.value);
                                }}
                                isInvalid={!!errors.regName}
                              >
                                <option>Select your Region</option>
                                {regions.map((region, index) => (
                                  <option
                                    key={index}
                                    style={{ textTransform: "capitalize" }}
                                    value={region.region.toLowerCase()}
                                  >
                                    {region.region.charAt(0).toUpperCase() +
                                      region.region.slice(1).toLowerCase()}
                                  </option>
                                ))}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                {errors.regName}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>

                          <Col xs={12} md={4}>
                            <Form.Group>
                              <Form.Label>Group Name</Form.Label>
                              <Form.Select
                                name="grpName"
                                value={values.grpName}
                                onChange={handleChange}
                                isInvalid={!!errors.grpName}
                              >
                                <option>Select your Group</option>
                                {groups.map((group, index) => (
                                  <option
                                    key={index}
                                    style={{ textTransform: "capitalize" }}
                                    value={group.group.toLowerCase()}
                                  >
                                    {group.group.charAt(0).toUpperCase() +
                                      group.group.slice(1).toLowerCase()}
                                  </option>
                                ))}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                {errors.grpName}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                      </>
                    ) : (
                      <>
                          <Col xs={12} md={4}>
                            <Form.Group>
                              <Form.Label>Region Name</Form.Label>
                              <Form.Select
                                name="regName"
                                value={values.regName}
                                onChange={handleChange}
                                disabled
                              >
                                <option>Select your Region</option>
                                {regions.map((region, index) => (
                                  <option
                                    key={index}
                                    style={{ textTransform: "capitalize" }}
                                    value={region.region.toLowerCase()}
                                  >
                                    {region.region.charAt(0).toUpperCase() +
                                      region.region.slice(1).toLowerCase()}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </Col>

                          <Col xs={12} md={4}>
                            <Form.Group>
                              <Form.Label>Group Name</Form.Label>
                              <Form.Select
                                name="grpName"
                                value={values.grpName}
                                onChange={handleChange}
                                disabled
                              >
                                <option>Select your Group</option>
                                {groups.map((group, index) => (
                                  <option
                                    key={index}
                                    style={{ textTransform: "capitalize" }}
                                    value={group.group.toLowerCase()}
                                  >
                                    {group.group.charAt(0).toUpperCase() +
                                      group.group.slice(1).toLowerCase()}
                                  </option>
                                ))}
                              </Form.Select>
                            </Form.Group>
                          </Col>
                      </>
                    )}
              </Row>

              <Row className="mb-3">
                <Col lg={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Residential Address Line 1</Form.Label>
                    <Form.Control
                      name="residentialAddress1"
                      value={values.residentialAddress1}
                      placeholder="Door Number, Street, and Area"
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.residentialAddress1}
                    />
                    <Form.Control.Feedback type="invalid">{errors.residentialAddress1}</Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Residential Address Line 2</Form.Label>
                    <Form.Control
                      name="residentialAddress2"
                      value={values.residentialAddress2}
                      placeholder="Door Number, Street, and Area"
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.residentialAddress2}
                    />
                    <Form.Control.Feedback type="invalid">{errors.residentialAddress2}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col lg={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Residential City</Form.Label>
                    <Form.Control
                      name="residentialCity"
                      value={values.residentialCity}
                      placeholder="City"
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.residentialCity}
                    />
                    <Form.Control.Feedback type="invalid">{errors.residentialCity}</Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Residential Pincode</Form.Label>
                    <Form.Control
                      name="residentialPin"
                      value={values.residentialPin}
                      placeholder="Pincode"
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.residentialPin}
                    />
                    <Form.Control.Feedback type="invalid">{errors.residentialPin}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <h2 style={{ textAlign: "center" }}>Business Information</h2>
              <Row className="mb-3">
                <Col lg={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Name of the business</Form.Label>
                    <Form.Control
                      name="businessName"
                      value={values.businessName}
                      placeholder="Name of your Business"
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.businessName}
                    />
                    <Form.Control.Feedback type="invalid">{errors.businessName}</Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Dealing In</Form.Label>
                    <Form.Control
                      name="dealingIn"
                      value={values.dealingIn}
                      placeholder="Type of Your business"
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.dealingIn}
                    />
                    <Form.Control.Feedback type="invalid">{errors.dealingIn}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              {/* <Row className="mb-3">
                <Col lg={12} xs={12}>
                  <Form.Group>
                    <Form.Label>Business Category</Form.Label>
                    <Form.Control
                      name="businessCategory"
                      value={values.businessCategory}
                      placeholder="Dealing in"
                      onChange={(e)=>{handleChange(e); handleSave(values)}}
                      isInvalid={!!errors.businessCategory}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.businessCategory}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row> */}

              <Row className="mb-3">
                <Col lg={12} xs={12}>
                  <Form.Group>
                    <Form.Label>Business Category</Form.Label>
                    <Row>
                      {["Manufacturer", "Distributor", "Wholesaler", "Retailer", "Service Provider", "Consultant"].map(
                        (category) => (
                          <Col xs={12} lg={4}>
                            <Form.Check
                              key={category}
                              type="checkbox"
                              id={category}
                              label={category}
                              name="businessCategory"
                              value={category}
                              checked={
                                Array.isArray(values.businessCategory) && values.businessCategory.includes(category)
                              }
                              onChange={(e) => {
                                handleChange(e);
                                handleSave(values);
                              }}
                            />
                          </Col>
                        )
                      )}
                    </Row>
                    {errors.businessCategory && (
                      <div className="invalid-feedback d-block">{errors.businessCategory}</div>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col lg={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Business Address Line 1</Form.Label>
                    <Form.Control
                      name="businessAddress1"
                      value={values.businessAddress1}
                      placeholder="Door Number, Street, and Area"
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.businessAddress1}
                    />
                    <Form.Control.Feedback type="invalid">{errors.businessAddress1}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col lg={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Business Address Line 2</Form.Label>
                    <Form.Control
                      name="businessAddress2"
                      value={values.businessAddress2}
                      placeholder="Door Number, Street, and Area"
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.businessAddress2}
                    />
                    <Form.Control.Feedback type="invalid">{errors.businessAddress2}</Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Business GST</Form.Label>
                    <Form.Control
                      name="businessGst"
                      value={values.businessGst}
                      placeholder="GST Number of your business"
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.businessGst}
                    />
                    <Form.Control.Feedback type="invalid">{errors.businessGst}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col lg={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Business City</Form.Label>
                    <Form.Control
                      name="businessCity"
                      value={values.businessCity}
                      placeholder="City"
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.businessCity}
                    />
                    <Form.Control.Feedback type="invalid">{errors.businessCity}</Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Business Pincode</Form.Label>
                    <Form.Control
                      name="businessPin"
                      value={values.businessPin}
                      placeholder="Pincode"
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.businessPin}
                    />
                    <Form.Control.Feedback type="invalid">{errors.businessPin}</Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Business Phone</Form.Label>
                    <Form.Control
                      name="businessPhone"
                      value={values.businessPhone}
                      placeholder="Business Phone"
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.businessPhone}
                    />
                    <Form.Control.Feedback type="invalid">{errors.businessPhone}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col lg={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Locations in India</Form.Label>
                    <Form.Control
                      name="businessNumIndia"
                      value={values.businessNumIndia}
                      placeholder="No. of business location in India"
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.businessNumIndia}
                    />
                    <Form.Control.Feedback type="invalid">{errors.businessNumIndia}</Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Locations Abroad</Form.Label>
                    <Form.Control
                      name="businessNumAbroad"
                      value={values.businessNumAbroad}
                      placeholder="No. of business location abroad"
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.businessNumAbroad}
                    />
                    <Form.Control.Feedback type="invalid">{errors.businessNumAbroad}</Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Year of Incorporation</Form.Label>
                    <Form.Control
                      name="businessIncorporated"
                      value={values.businessIncorporated}
                      placeholder="Business is Incorporated since"
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.businessIncorporated}
                    />
                    <Form.Control.Feedback type="invalid">{errors.businessIncorporated}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="mb-3">
                <Col lg={12} xs={12}>
                  <Form.Group>
                    <Form.Label>What’s the purpose of your participation in Exclusive Empresaas Event</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      name="businessSummit"
                      value={values.businessSummit}
                      placeholder="What would you like to take away from this Summit"
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.businessSummit}
                    />
                    <Form.Control.Feedback type="invalid">{errors.businessSummit}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <h2 style={{ textAlign: "center" }}>Payment Information</h2>
              <Row className="mb-3">
                <Col lg={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Payment Amount</Form.Label>
                    <Form.Control
                      name="paymentAmount"
                      value={values.paymentAmount}
                      placeholder="Rs. 11,999"
                      isInvalid={!!errors.paymentAmount}
                    />
                    <Form.Control.Feedback type="invalid">{errors.paymentAmount}</Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Payment Date</Form.Label>
                    <Form.Control
                      type="date"
                      name="paymentDate"
                      value={values.paymentDate}
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.paymentDate}
                    />
                    <Form.Control.Feedback type="invalid">{errors.paymentDate}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Bank Name</Form.Label>
                    <Form.Control
                      name="paymentBank"
                      value={values.paymentBank}
                      placeholder="Bank account"
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.paymentBank}
                    />
                    <Form.Control.Feedback type="invalid">{errors.paymentBank}</Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={4} xs={12}>
                  <Form.Group>
                    <Form.Label>Branch</Form.Label>
                    <Form.Control
                      name="paymentBranch"
                      value={values.paymentBranch}
                      placeholder="Branch in"
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.paymentBranch}
                    />
                    <Form.Control.Feedback type="invalid">{errors.paymentBranch}</Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={4} xs={12}>
                  <Form.Group>
                    <Form.Label>UTR Number/ UPI Paymnent Number</Form.Label>
                    <Form.Control
                      name="paymentUTR"
                      value={values.paymentUTR}
                      placeholder="Payment UTR number"
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.paymentUTR}
                    />
                    <Form.Control.Feedback type="invalid">{errors.paymentUTR}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <h2 style={{ textAlign: "center" }}>Identity Proof</h2>
              <Row className="mb-3">
                <Col lg={3} xs={12}>
                  <Form.Group>
                    <Form.Label>Your Photo</Form.Label>
                    <Form.Control
                      name="photo"
                      type="file"
                      accept="image/jpeg, image/jpg, image/png"
                      onChange={(event) => {
                        setphoto(event.target.files[0]);
                        handleChange(event);
                      }}
                      isInvalid={!!errors.photo}
                    />
                    <Form.Control.Feedback type="invalid">{errors.photo}</Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={3} xs={12}>
                  <Form.Group>
                    <Form.Label>ID Proof Type</Form.Label>
                    <Form.Select
                      name="idType"
                      value={values.idType}
                      placeholder="ID Type"
                      onChange={(e) => {
                        handleChange(e);
                        handleSave(values);
                      }}
                      isInvalid={!!errors.idType}
                    >
                      <option value="">Select ID Type</option>
                      <option value="Aadhar">Aadhar</option>
                      <option value="Passport">Passport</option>
                      <option value="Driving License">Driving License</option>
                      <option value="Driving License">Voter ID Card</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={3} xs={12}>
                  <Form.Group>
                    <Form.Label>Aadhar Front</Form.Label>
                    <Form.Control
                      name="aadharFront"
                      type="file"
                      onChange={(event) => {
                        setaadharFront(event.target.files[0]);
                        handleChange(event);
                      }}
                      isInvalid={!!errors.aadharFront}
                    />
                    <Form.Control.Feedback type="invalid">{errors.aadharFront}</Form.Control.Feedback>
                  </Form.Group>
                </Col>

                <Col lg={3} xs={12}>
                  <Form.Group>
                    <Form.Label>Aadhar back</Form.Label>
                    <Form.Control
                      name="aadharback"
                      type="file"
                      onChange={(event) => {
                        setaadharback(event.target.files[0]);
                        handleChange(event);
                      }}
                      isInvalid={!!errors.aadharback}
                    />
                    <Form.Control.Feedback type="invalid">{errors.aadharback}</Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <Button className="btn--outline--dark" type="submit" disabled={isSubmitting}>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    {isSubmitting ? "Submitting..." : "Submit"}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </div>

      <Modal centered show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Form Submitted </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
          <p>Your Form Has successfully been submitted. Please check your email for confirmation.</p>
        </Modal.Body>

        <Modal.Footer>
          <Button className="btn--four" onClick={handleModalClose}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EERegister;
