import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../App.css";
import {
  Row,
  Col,
  Container,
  Card,
  ListGroup,
  Alert,
  Toast,
  Image,
  Button,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "./HomeScreen.css";
import axios from "axios";
import { LinkContainer } from "react-router-bootstrap";
import image from "../images/strotHome.jpg";

import eeLogo from "../images/eeLogo.jpg";
import speakerVishal from "../images/strotVishal.jpg";

const HomeScreen = () => {
  const user = useSelector((state) => state.authentication.user);

  const navigate = useNavigate();

  // useEffect(() => {
  //   navigate("/ee");
  // }, []);

  const checkFormStatus = async () => {
    try {
      const response = await axios.get(
        `/api/form/user/ee/application/${user.user_id}`,
        {}
      );
      console.log(response.data);
      if (response.data.isFormFilled === true) {
        navigate("/ee");
      } else if (response.data.isFormFilled === false) {
        navigate("/ee/register/");
      }
    } catch (error) {
      console.error("Error checking form status:", error);
    }
  };

  useEffect(() => {
    console.log(typeof user);
    if (typeof user === "undefined" || typeof user.user_id === "undefined") {
      navigate("/ee");
    } else {
      checkFormStatus();
    }
  }, []);

  // const fetchData = async () => {
  //   const response = await axios.get(`/user/user/${user?.user_id}`);
  //   setInfos(response.data);
  // };

  // useEffect(() => {
  //   fetchData();
  // }, []);

  return (
    <div>
      <div className="homeContainer"></div>
    </div>
  );
};

export default HomeScreen;
